<template>
  <vx-card>
    <form>
      <div class="mb-4 px-4">
        <fieldset class="vx-row rounded-lg pb-3 px-1 pt-1" style="border-color: rgba(255,255,255,0.6)">
          <legend class="mx-3 px-2"><strong>{{$t('fields.code')}}</strong></legend>
          <div class="vx-col my-2 w-full">
            <vs-input v-validate="'required_if:canPublished,true'" class="w-full" name="code" placeholder="code" v-model="brand.code" />
            <span class="text-danger text-sm" v-show="errors.has('code')">{{ errors.first('code') }}</span>
          </div>
        </fieldset>
      </div>
      <div class="mb-4 px-4">
        <fieldset class="vx-row rounded-lg pb-3 px-1 pt-1" style="border-color: rgba(255,255,255,0.6)">
          <legend class="mx-3 px-2"><strong>{{$t('fields.title')}}</strong></legend>
          <div class="vx-col my-2 md:w-1/3 w-full">
            <vs-input v-validate="'required'" class="w-full" name="name_tm" placeholder="Turkmen" v-model="brand.name_tm" />
            <span class="text-danger text-sm" v-show="errors.has('name_tm')">{{ errors.first('name_tm') }}</span>
          </div>
          <div class="vx-col my-2 md:w-1/3 w-full">
            <vs-input v-validate="'required_if:canPublished,true'" class="w-full" name="name_ru" placeholder="Russian" v-model="brand.name_ru" />
            <span class="text-danger text-sm" v-show="errors.has('name_ru')">{{ errors.first('name_ru') }}</span>
          </div>
          <div class="vx-col my-2 md:w-1/3 w-full">
            <vs-input v-validate="'required_if:canPublished,true'" class="w-full" name="name_en" placeholder="English" v-model="brand.name_en" />
            <span class="text-danger text-sm" v-show="errors.has('name_en')">{{ errors.first('name_en') }}</span>
          </div>
        </fieldset>
      </div>
        <div class="mb-4 px-4">
          <fieldset class="vx-row rounded-lg pb-3 px-1 pt-1" style="border-color: rgba(255,255,255,0.6)">
            <legend class="mx-3 px-2"><strong>{{$t('fields.categories')}}</strong></legend>
            <div class="vx-col my-2 w-full">
              <v-select multiple v-validate="'required_if:canPublished,true'" :options="categories" :label="`name_${$i18n.locale}`" name="categories" :placeholder="$t('select') + ' ' + $t('categories')" v-model="brand.categories" :reduce="opt => opt.id" />
              <span class="text-danger text-sm" v-show="errors.has('categories')">{{ errors.first('categories') }}</span>
            </div>
          </fieldset>
        </div>
      <div class="mb-4">
        <fieldset class="rounded-lg px-1" style="border-color: rgba(255,255,255,0.6)">
          <legend class="mx-3 px-2"><strong>{{$t('fields.properties')}}</strong></legend>
          <ul class="vx-row centerx px-2">
            <li class="vx-col my-4 ml-2 mr-4 flex">
              <vs-switch name="isActive" ref="isActive" v-model="brand.isActive" />
              <label class="ml-3">{{$t('isActive')}}?</label>
            </li>
            <li class="vx-col my-4 ml-2 mr-4 flex">
              <vs-switch v-validate="'required_if:isActive,true'" name="canPublished" ref="canPublished" v-model="brand.canPublished" />
              <label class="ml-3" :class="errors.has('canPublished') ? 'text-danger' : ''">{{$t('canBePublished')}}?</label>
            </li>
            <span class="text-danger text-sm" v-show="errors.has('canPublished')">{{ errors.first('canPublished') }}</span>
          </ul>
        </fieldset>
      </div>
      <div class="vx-row">
        <div class="vx-col upload-img mt-3" v-if="!brand.photo">
          <input v-validate="'required_if:canPublished,true'" name="logo" type="file" class="hidden" ref="uploadImgInput" @change="updateCurrImg" accept="image/*">
          <vs-button @click="$refs.uploadImgInput.click()" type="border" icon-pack="feather" icon="icon-upload">{{ $t('upload') }} {{ $t('image') }}</vs-button>
          <span class="text-danger text-sm" v-show="errors.has('logo')">{{ errors.first('logo') }}</span>
        </div>
      </div>
      <template v-if="brand.photo">
        <div class="img-container w-64 mx-auto flex items-center justify-center">
          <img :src="brand.photo" alt="img" class="responsive">
        </div>
        <div class="modify-img flex justify-between mt-5">
          <input type="file" class="hidden" ref="updateImgInput" @change="updateCurrImg" accept="image/*">
          <vs-button class="mr-4" type="flat" @click="$refs.updateImgInput.click()">{{ $t('update') }} {{ $t('image') }}</vs-button>
          <vs-button type="flat" color="#999" @click="brand.photo = null">{{ $t('remove') }} {{ $t('image') }}</vs-button>
        </div>
      </template>
      <div class="vx-row px-4">
        <vs-button type="filled" @click.prevent="submitForm" class="ml-auto mt-5 block">{{$t('submit')}}</vs-button>
        <vs-button type="border" color="warning" @click.prevent="$router.back()" class="mr-auto ml-4 mt-5 block">{{$t('cancel')}}</vs-button>
      </div>
    </form>
  </vx-card>
</template>

<script>
import vSelect from 'vue-select'
export default {
  components: { vSelect },
  data () {
    return {
      brand: {
        code: '',
        name_tm: '',
        name_ru: '',
        name_en: '',
        categories: [],
        photo: null,
        isActive: false,
        canPublished: false
      },
      categories: []
    }
  },
  async created () {
    await this.$http.get('/categories', { params: { isActive: true } }).then(response => {
      if (response.status === 200) {
        this.categories = response.data.data
      }
    })
  },
  methods: {
    submitForm () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$http.post('/brands/add', this.brand).then(response => {
            if (response.status === 201) {
              this.$vs.notify({
                title: this.$t('notify.success'),
                text: `${this.$t('brand')} ${this.$t('notify.sCreated')}`,
                iconPack: 'feather',
                icon: 'icon-check-circle',
                color: 'primary'
              })
              this.$router.push('/brands')
            }
          }).catch(err => {
            this.$vs.notify({
              title: this.$t('notify.error'),
              text: err.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          })
        } else {
          this.$vs.notify({
            title: this.$t('notify.error'),
            text: this.$t('notify.formInvalid'),
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      })
    },
    updateCurrImg (input) {
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {
          this.brand.photo = e.target.result
        }
        reader.readAsDataURL(input.target.files[0])
      }
    }
  }
}
</script>
